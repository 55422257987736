.date-range-picker {
  width: fit-content;
  position: relative;

  &__trigger {
    padding: .5rem 1rem;

    border: 1px solid $purple;
    border-radius: .6rem;

    cursor: pointer;

    transition: background-color 200ms ease;

    &:hover {
      background-color: $purple;
      color: $white;
    }
  }
  &__picker {
    position: absolute;
    margin-top: 1rem;

    background-color: $white;
    box-shadow: 0px 0px 20px 0px rgba(229,229,229,0.5);

    z-index: 20;
  }
}
