.dashboard {

  &__controls {
    display: flex;
    justify-content: flex-end;

    margin-bottom: 2rem;

    .date-range-picker__picker {
      right: 0 !important;
    }
  }

  &__charts {
    display: grid;

    grid-template-columns: repeat(12, 1fr);
    grid-auto-rows: 8rem;
    grid-row-gap: 1rem;
    grid-column-gap: 1rem;
  }
}

.chart {
  height: 100%;
  width: 100%;

  background-color: $white;
  box-shadow: 0px 0px 20px 0px rgba(229,229,229,0.5);

  border-radius: 15px;

  &__label {
    padding: 1rem 1.5rem 1rem 1.5rem;
    line-height: 1rem;
    display: block;

    color: $text--meta;

    font-size: 0.9rem;
  }

  &__content {
    display: flex;
    width: 100%;
    height: calc(100% - 3rem);

    justify-content: center;
    align-items: center;
  }

  &--number {
    &__value {
      display: inline-block;
      width: 100%;
      
      text-align: center;

      //font-size: 1.6rem;
      font-weight: 300;
    }
  }
}
