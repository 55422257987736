.month-calendar__pill {
  display: inline-block;
  width: .6rem;
  height: .6rem;

  border-radius: 1.2rem;
  margin-left: 0.2rem;
}

.month-calendar__title {
  width: calc(75%  - 1.5rem);
  overflow: hidden;
  margin: 0 .5rem;

  color: #333;
}

.month-calendar__time {
  color: #999;
}