.employee-statistics {
  display: flex;
  flex-wrap: wrap;

  margin-bottom: 2rem;

  h1 {
    display: block;
    width: 100%;
    font-size: 1.5rem;
  }

  &__date-selector {
    width: 100%;
  }

  &__year, &__month {
    width: 100%;
    padding: 1rem 0;
    
    > span {
      font-size: 1.2rem;
    }
  }

  &__statistics-wrapper {
    display: flex;
    width: 100%;
    margin-top: 1rem;

    > div {
      width: 100%;
      margin-right: 1rem;

      > h5 {
        color: $purple;
        font-size: 1.2rem;
      }

      > div {
        width: 100%;
      }
    }
  }
}
