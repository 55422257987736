.alerts-overview {

  &__bar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    h3 {
      font-size: 1.2rem;
      font-weight: 500;

      margin: 0;
    }
  }

  &__controls {
    display: flex;

    .date-range-picker {
      span {
        color: $black;
      }

      &__picker {
        top: 0;
        right: 0;
        margin-top: 3rem;
      }
    }

    > div {
      display: flex;
      align-items: center;

      font-size: .9rem;
      padding: 0 1rem;

      span {
        color: $sub-grey;
      }

      ul {
        display: flex;

        margin: 0 0 0 1rem;
        padding: 0;

        li {
          list-style-type: none;
          padding: .5rem;
          cursor: pointer;

          &.active {
            color: $purple;
          }
        }
      }
    }
  }

  &__groups {
    margin-top: 3rem;
  }

  &__group {
    display: flex;
    align-items: center;

    margin-bottom: 2rem;

    > span {
      width: 20%;
    }

    &-items {
      display: flex;
      width: 80%;

      overflow-y: scroll;
    }
  }

  &-item {
    position: relative;
    min-width: fit-content;
    padding: 1rem 2.7rem;
    margin: 1rem .5rem;

    box-shadow: 0px 0px 20px 0px rgba(229,229,229,0.5);
    border-radius: 15px;

    &__type {
      display: block;
      font-size: .75rem;
    }

    &__date {
      display: block;
      font-size: .75rem;
      color: $text--meta;

      margin-bottom: .5rem;
    }

    &__meta {
      display: block;
      font-size: .75rem;
    }

    &__description {
      display: block;
      margin: 1rem 0 0 0;
      font-size: .95rem;
    }

    &__process {
      display: none;
    }

    &:hover {
      .alerts-overview-item__process {
        display: block;

        position: absolute;
        top: 0;
        right: 0;

        background: $light-grey;
        color: $purple;
        font-size: .9rem;
        padding: .5rem 1rem;
        border-bottom-left-radius: 15px;

        cursor: pointer;
      }
    }
  }
}
