.procedure {

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__attachments {
    border: 1px solid #eee;
    padding: 1rem 1rem;

    &__label {
      display: inline-block;
      width: 100%;
      font-weight: bold;

      padding-bottom: 1rem;
      border-bottom: 1px solid #eee;
    }

    &__attachment {
      display: flex;
      align-items: center;
      padding: 1rem 0;

      span {
        width: 18rem;
        word-wrap: none;
        overflow-y: hidden;
        margin-right: 1rem;
      }
    }
  }
  
  &__info {

    &__name {
      font-size: 2rem;
    }

    &__categories {
      margin-left: 1rem;
    }
  }
}