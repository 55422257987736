.data-table-wrapper {
  height: 45rem;

  table {
    height: 100%;
  }

  tbody tr:hover {
    background-color: $light-grey;
    cursor: pointer;
  }

  td, th {
    white-space: nowrap;
  }

  thead, thead td, thead th {
    position: sticky;
    top: 0;
    z-index: 4;

    background-color: white;
  }

  tfoot, tfoot td, tfoot th {
    position: sticky;
    bottom: 0;
    z-index: 4;

    background-color: white;
  }

  thead tr th {
    position: relative;

    .data-table__heading__options {
      position: absolute;
      top: 100%;

      padding: 1rem .5rem;
      
      background: white;
      box-shadow: 0px 0px 20px 0px rgba(229,229,229,0.5);

      h5 {
        font-size: .9rem;
        font-weight: bold;
        margin: 0;
      }

      &__filters {
        margin-top: 1rem;

        ul {
          margin: .5rem 0 0 0;
          padding: 0;

          max-height: 10rem;
          overflow: scroll;

          li {
            list-style-type: none;
            text-transform: none;
            color: $dark-grey;
          }
        }
      }

      &__sort {
        display: flex;

        h5 {
          margin: 0 1rem 0 0;
        }

        span {
          margin-left: .5rem;
          text-transform: none;

          color: $dark-grey;
          cursor: pointer;

          &:hover, &.active {
            color: $purple;
          }
        }
      }
    }
  }
}
