.fc-toolbar-title {
  font-size: 1.2rem !important;
}

.fc .fc-button-primary {
  background-color: transparent !important;
  color: #333 !important;
  border: none !important;

  transition: color 200ms ease;
}

.fc .fc-button-primary:focus {
  box-shadow: none !important;
}

.fc .fc-button-primary:hover {
  color: #1e87f0 !important;
}

.fc .fc-button-primary.fc-button-active {
  color: #1e87f0 !important;
}

.fc .fc-toolbar.fc-header-toolbar {
  margin-bottom: 0.5rem !important;
}

.fc-theme-standard td, .fc-theme-standard th {
  border-color: #f7f7f7 !important;
}

.fc-theme-standard .fc-scrollgrid {
  border-color: #f7f7f7 !important;
}

.fc th {
  font-weight: 400 !important;
}

.fc-col-header-cell a {
  color: #333 !important;
}

.fc .fc-timegrid-col.fc-day-today, .fc .fc-daygrid-day.fc-day-today {
  background-color: rgba(30, 135, 240, 0.15) !important;
}

.fc-timegrid-event {
  border-radius: 0 !important;
}

.fc-scroller::-webkit-scrollbar-track
{
	background-color: #F5F5F5;
}

.fc-scroller::-webkit-scrollbar
{
	width: 5px;
	background-color: #F5F5F5;
}

.fc-scroller::-webkit-scrollbar-thumb
{
  background-color: #333;
}