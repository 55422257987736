$navigation-width: 18rem;

.navigation {
  display: flex;
  flex-direction: column;
  
  position: fixed;
  width: $navigation-width;
  height: 100vh;
  overflow-y: auto;

  &__info {
    display: flex;
    align-items: center;
    justify-content: center;

    padding: 4rem 0rem;

    &__image {
      width: 4rem;
      height: 4rem;

      margin-right: .8rem;

      border-radius: .5rem;
    }

    &__company {
      font-size: 1.25rem;
      font-weight: 400;
    }

    &__user {
      font-size: 0.85rem;
      line-height: 0.85rem;
      color: $text--meta;
    }
  }

  &__menu {
    display: flex;
    flex-direction: column;
    height: 100%;

    list-style-type: none;

    margin: 0;
    padding: 0;

    &__link, &__link-group {
      display: flex;
      align-items: center;
      justify-content: space-between;

      margin: 0.2rem 1.6rem;
      padding: 0.8rem 1rem;

      text-decoration: none;
      color: $text--dark;

      &__icon {
        position: relative;
        padding: 0.7rem;
        border-radius: .7rem;
        background-color: lighten($purple, 38%);

        transition: border-radius 200ms ease,
                    background-color 200ms;
      }

      &--danger {
        .navigation__menu__link__icon {
          background-color: lighten($red, 30%);
        }

        &:hover {
          color: $red !important;

          .navigation__menu__link__icon {
            background-color: $red !important;
          }
        }
      }

      &:hover, &--active {
        text-decoration: none;
        color: $purple;
      }

      &:hover > &__icon, &--active > &__icon {
        background-color: $purple;
        color: $white;
        border-radius: 1.3rem;
      }
    }

    &__link-group {
      position: relative;
      flex-wrap: wrap;

      &:hover > &__links {
        visibility: visible;
        opacity: 1;
      }

      &__links {
        position: absolute;
        top: 100%;
        right: 0;

        z-index: 10;

        background-color: $white;

        border-radius: 0.2rem;

        box-shadow: 0px 0px 20px 0px rgba(229,229,229,0.5);

        width: 75%;
        visibility: hidden;
        opacity: 0;

        transition: visibility 0s linear 0s, opacity 300ms;

        .navigation__menu__link {
          font-size: .85rem;

          margin: 0.5rem 0rem;
          padding: 0.4rem 1.1rem;

          &__icon {
            padding: 0.5rem;
            border-radius: .5rem;
          }
        }
      }
    }
  }

  &__actions {
    list-style-type: none;
    justify-self: end;

    margin: auto 0 1rem 0;
    padding: 0;

    li {
      padding: 0.6rem 0.8rem;

      a {
        color: $white;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}
