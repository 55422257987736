@import url('https://fonts.googleapis.com/css2?family=Rubik:wght@300;400;500;600;700&display=swap');

$white: #FFFFFF;
$black: #000000;

$dark-grey: #1E1D20;
$light-grey: #F5F4F5;
$sub-grey: #D8D8D8;
$red: #FB3640;
$yellow: #E09F3E;
$purple: #695FAB;

$text--dark: $dark-grey;
$text--light: $white;
$text--meta: lighten($dark-grey, 60%);

html, select {
  font-family: 'Rubik', sans-serif !important;
  color: $text--dark;
}

.uk-h1, .uk-h2, .uk-h3, .uk-h4, .uk-h5, .uk-h6, .uk-heading-2xlarge, .uk-heading-large, .uk-heading-medium, .uk-heading-small, .uk-heading-xlarge, h1, h2, h3, h4, h5, h6 {
  font-family: 'Rubik', sans-serif !important;
  color: $text--dark;
}

.white-text {
  color: $text--light !important;
}

.red-text {
  color: $red !important;
}

.cp {
  cursor: pointer;
}

.align-center {
  text-align: center;
}

.margin-top-auto {
  margin-top: auto !important;
}

select {
  min-width: 15rem;
  padding: .4rem .2rem;

  border-radius: .4rem;
  border-color: $light-grey;
}

.date-input {
  position: relative;

  > span {
    display: inline-block;
    width: 100%;

    padding: .7rem 1rem;
    border: 1px solid $light-grey;

    box-sizing: border-box;

    cursor: pointer;


    &:hover {
      border-color: $purple;
    }
  }

  > div {
    position: absolute;
    top: 100%;
    left: 50%;

    transform: translateX(-50%);

    select {
      min-width: 0;
    }
  }
}

.multi-date-input {
  .DayPickerInput {
    width: 100%;
    * {
      font-family: 'Rubik';
    }

    .DayPicker-Day--selected:not(.DayPicker-Day--disabled):not(.DayPicker-Day--outside) {
      background-color: $purple;
      border-radius: 0;
    }

    > input {
      display: inline-block;
      width: 100%;

      padding: .7rem 1rem;
      border: 1px solid $light-grey;

      font-size: 1rem;
      line-height: 1.5;

      box-sizing: border-box;

      cursor: pointer;

      &:hover {
        border-color: $purple;
      }

      &:focus {
        outline: none;
      }
    }
  }
}

.time-input {
  width: 100%;
  display: flex;

  input {
    display: inline-block;
    width: 100%;

    font-size: 1rem;

    padding: .7rem 1rem;
    border: 1px solid $light-grey;

    &:focus, &:hover {
      outline: none;
      border-color: $purple;
    }
  }
}
